.tooltip-parent {
    position: relative;
}

.tooltip-content {
    background-color: var(--tooltip-color);
    position: absolute;
    color: var(--tooltip-text-color);
    z-index: 50;
    margin-inline: auto;
    width: max-content;
    max-width: 150%;
    padding: 8px;
    border-radius: 5px;
}

.tooltip-content.d-block.tooltip-no-hover {
    animation: var(--tooltip-animation) 350ms;
}

.tooltip-content::after {
    content: " ";
    position: absolute;
    border-style: solid;
}

.tooltip-hover {
    visibility: hidden;
}

.tooltip-parent:hover .tooltip-hover {
    visibility: visible;
    animation: var(--tooltip-animation) 350ms;
}

.tooltip-top {
    bottom: 110%;
    left: 50%;
    transform: translateX(-53%);
    --tooltip-animation: showTooltipTop;
}

.tooltip-top::after {
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-color: var(--tooltip-color) transparent transparent transparent;
}

.tooltip-bottom {
    top: 110%;
    left: 50%;
    transform: translateX(-53%);
    --tooltip-animation: showTooltipBottom;
}

.tooltip-bottom::after {
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-color: transparent transparent var(--tooltip-color) transparent;
}

.tooltip-left {
    right: 110%;
    top: 50%;
    transform: translateY(-53%);
    --tooltip-animation: showTooltipLeft;
}

.tooltip-left::after {
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-color: transparent transparent transparent var(--tooltip-color);
}

.tooltip-right {
    left: 110%;
    top: 50%;
    transform: translateY(-53%);
    --tooltip-animation: showTooltipRight;
}

.tooltip-right::after {
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-color: transparent var(--tooltip-color) transparent transparent;
}

.tooltip-top-right {
    border-bottom-left-radius: 0;
    left: 120%;
    bottom: 100%;
}

.tooltip-top-right::after {
    border-style: solid;
    border-width: 0 0px 16px 16px;
    left: -10px;
    bottom: -2px;
    transform: rotate(343deg);
    border-color: transparent transparent var(--tooltip-color) transparent;
}

.tooltip-top-left {
    border-bottom-right-radius: 0;
    right: 110%;
    bottom: 100%;
}

.tooltip-top-left::after {
    border-style: solid;
    border-width: 0 0px 16px 16px;
    right: 0;
    bottom: -10px;
    transform: rotate(253deg);
    border-color: transparent transparent var(--tooltip-color) transparent;
}

.tooltip-default {
    --tooltip-color: #009ef7;
    --tooltip-text-color: #FFFFFF
}

.tooltip-error {
    --tooltip-color: #F44336;
    --tooltip-text-color: #FFFFFF
}

.tooltip-information {
    --tooltip-color: #F5F5F5;
    --tooltip-text-color: #000000
}

@keyframes showTooltipTop {
    0% {
        opacity: 0;
        bottom: 90%;
    }

    33% {
        bottom: 120%;
    }

    66% {
        bottom: 100%;
    }

    90% {
        bottom: 112%;
    }

    95% {
        bottom: 108%;
    }

    100% {
        opacity: 1;
        bottom: 110%;
    }
}

@keyframes showTooltipBottom {
    0% {
        top: 90%;
    }

    33% {
        top: 120%;
    }

    66% {
        top: 100%;
    }

    90% {
        top: 112%;
    }

    95% {
        top: 108%;
    }

    100% {
        top: 110%;
    }
}

@keyframes showTooltipLeft {
    0% {
        right: 90%;
    }

    33% {
        right: 120%;
    }

    66% {
        right: 100%;
    }

    90% {
        right: 112%;
    }

    95% {
        right: 108%;
    }

    100% {
        right: 110%;
    }
}

@keyframes showTooltipRight {
    0% {
        left: 90%;
        background-color: transparent;
    }

    33% {
        left: 120%;
    }

    66% {
        left: 100%;
    }

    90% {
        left: 112%;
    }

    95% {
        left: 108%;
    }

    100% {
        left: 110%;
    }
}