.radio-button {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    display: inline-block;
    margin-right: 0.5rem;
    position: relative;
    outline: none;
    cursor: pointer;
    border: 2px solid #ccc;
    background-color: transparent;
}

.radio-button:checked {
    border: 2px solid orange;
    background-color: orange;
}